@font-face {
  font-family: Glora300;
  src: url(assets/fonts/glora/Glora\ light.ttf);
   font-display: swap;
}

@font-face {
  font-family: Glora400;
  src: url(assets/fonts/glora/Glora\ Regular.ttf);
   font-display: swap;
}

@font-face {
  font-family: Glora500;
  src: url(assets/fonts/glora/Glora\ Medium.ttf);
   font-display: swap;
}

@font-face {
  font-family: Glora600;
  src: url(assets/fonts/glora/Glora\ Semi\ Bold.ttf);
   font-display: swap;
}

@font-face {
  font-family: Glora700;
  src: url(assets/fonts/glora/Glora\ Bold.ttf);
   font-display: swap;
}

@font-face {
  font-family: Glora800;
  src: url(assets/fonts/glora/Glora\ Black.ttf);
   font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');


.HomeSwiper .swiper-pagination-bullet {
  background-color: #D9D9D9;
  opacity: 0.5;
}

.HomeSwiper .swiper-pagination-bullet-active {
  opacity: 1;
}

.HomeSwiper .swiper-pagination-bullets {
  bottom: 105px !important;
}

.HomeSwiper .swiper-slide {
  position: relative;
}

.HomeSwiper .swiper-pagination-bullets {
  display: none;
}

.loaderStyle {
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: #000;
}

.frame360 {
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 1000;
  overflow: hidden;
}

.comingSoon {
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100vw;
  position: relative;
  z-index: 1000;
  padding: 1rem;
  background: #E8E8E8;
}

.comingSoon svg {
  width: 100%;
  max-width: 700px;
  height: auto;
}

@media (max-width: 991.98px) {
  .HomeSwiper .swiper-pagination-bullets {
    display: block;
  }
}